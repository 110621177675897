import style from './about.module.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import GitHub from '../../components/Icons/GitHub'
import SocialLink from '../../components/SocialLink/SocialLink'
const About = () => {
  const [isEnglish, setIsEnglish] = useState(true)

  const aboutText = () => {
    const text = isEnglish
      ? <>
          <h3>Hi<span className={style.highlight}>! </span>I&apos;m <span className={style.highlight}>Jeka</span></h3>
          <p>
            After 11 years mastering the world of specialty coffee, I’ve pivoted and made my way to the even more fascinating world of <span className={style.highlight}><strong>JavaScript Full Stack Development</strong></span>.
          </p>
          <p>
            In 2016, I began my journey studying <span className={style.highlight}><strong>HTML</strong></span> / <span className={style.highlight}><strong>CSS</strong></span> and <span className={style.highlight}><strong>PHP</strong></span>. I started with my own personal passion project: my business web page. I spent months manipulating its form on <span className={style.highlight}><strong>WordPress</strong></span> to my evolving business needs. With constant persistence and perseverance, I conquered all my initial challenges.
          </p>
          <p>
            My curiosity is my motivation. It pushes me to learn more, seeking perfection and simplicity in my endeavors. My colleagues have described me as dedicated, passionate, and hardworking. Most importantly, I’m proud of my ability to work effectively and efficiently in a team environment.
          </p>
          <p>
            My limited experience should not be seen as a hinderance but as an opportunity. It pushes me to work harder and quickly reach the level of my peers.
          </p>
          <p>
            Some of my greatest accomplishments to date include developing several <span className={style.highlight}><strong>WordPress</strong></span> themes from scratch, as well as multiple landing pages used for commercial purposes. All of my work is fully responsive, with special focus on <em>“mobile first”</em>. Most recently, I completed a 3-month intensive course at <span className={style.highlight}><strong>“Code4Jobs”</strong></span>, a boot camp for Full Stack development specializing in <span className={style.highlight}><strong>NodeJS</strong></span> / <span className={style.highlight}><strong>ExpressJS</strong></span> / <span className={style.highlight}><strong>NestJS</strong></span> / <span className={style.highlight}><strong>ReactJS</strong></span> and <span className={style.highlight}><strong>Angular</strong></span>. My progress compounded daily and has left me with countless more transferable skills. You can check some of my works in my <SocialLink linkProps={{ href: 'https://github.com/greezaaa', children: <GitHub />, title: 'Check GitHub profile' }} /> or check my <Link to="../works"><em>&quot;works&quot;</em></Link> section.
          </p>
          <p>
            Thanks for dropping by to get to know me. I’m always looking forward to building my network with other professionals in the field, sharing ideas, and working together. Let’s chat soon!
          </p>
        </>
      : <>
          <h3>Hola, soy <span className={style.highlight}>Jeka</span></h3>
          <p>Después de 11 años dominando el mundo del café de especialidad, he dado un giro y me he abierto camino hacia el mundo aún más fascinante, <span className={style.highlight}><strong>desarrollo Full Stack de JavaScript</strong></span>.</p>

          <p>En 2016, comencé mi camino estudiando <span className={style.highlight}><strong>HTML</strong></span> / <span className={style.highlight}><strong>CSS</strong></span> y <span className={style.highlight}><strong>PHP</strong></span>. Empecé con mi propio proyecto de pasión personal: página web de mi empresa. Pasé tiempo manipulando su estructura en WordPress para adaptarlo a mis necesidades comerciales. Con persistencia y perseverancia constante he conseguido el resultado deseado</p>

          <p>Mi curiosidad es mi motivación. Me empuja a aprender más, buscando la perfección y la sencillez en mis emprendimientos. Mis colegas me han descrito como dedicado, apasionado y trabajador. Lo que es más importante, estoy orgulloso de mi capacidad para trabajar en equipo de manera eficaz y eficiente.</p>

          <p>Mi limitada experiencia no debe verse como un obstáculo, sino como una oportunidad. Me empuja a trabajar más duro para alcanzar a mis compañeros.</p>

          <p>Algunos de mis mayores logros hasta la fecha incluyen el desarrollo de varios temas de <span className={style.highlight}><strong>WordPress</strong></span> desde cero, así como múltiples <em>&quot;Landing Pages&quot;</em> utilizadas con fines comerciales. Todo mi trabajo es totalmente receptivo, con un enfoque especial en <em>&quot;mobile first&quot;</em>. Recientemente, completé un curso intensivo de 3 meses empartido por <span className={style.highlight}><strong>&quot;Code4Jobs&quot;</strong></span>, un bootcamp para el desarrollo Full Stack especializado en <span className={style.highlight}><strong>NodeJS</strong></span> / <span className={style.highlight}><strong>ExpressJS</strong></span> / <span className={style.highlight}><strong>NestJS</strong></span> / <span className={style.highlight}><strong>ReactJS</strong></span> y <span className={style.highlight}><strong>Angular</strong></span>. Mi progreso se agravó a diario y me ha dejado innumerables habilidades más transferibles. Podras encontrar todos mis proyectos en mi perfil de <SocialLink linkProps={{ href: 'https://github.com/greezaaa', children: <GitHub />, title: 'Check GitHub profile' }} /> o, si lo prefieres pasate por la secion <Link to="../works"><em>&quot;works&quot;</em></Link>.</p>

          <p>Gracias por pasarte a conocerme. Siempre tengo ganas de construir mi red con otros profesionales en el campo, compartir ideas y trabajar juntos.
            <Link to="/contact" title='Get in touch'><em> ¡Hablemos!</em></Link>
          </p>
        </>
    return text
  }
  return (

    <div className={style.about_wrapper}>
      <div className={style.lang_selector}>
        <button onClick={() => setIsEnglish(true)} className={`${style.btn_lang} ${isEnglish ? style.selected : style.unselected}`} disabled={isEnglish}>Eng</button>
        <button onClick={() => setIsEnglish(false)} className={`${style.btn_lang} ${!isEnglish ? style.selected : style.unselected}`} disabled={!isEnglish}>Esp</button>
      </div>
    {aboutText()}
  </div>

  )
}
export default About
