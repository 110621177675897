import { Link } from 'react-router-dom'
import style from './home.module.scss'
import Arrow from '../../components/Icons/Arrow'
import photoHero from '../../assets/media/photo_hero.webp'

const Home = () => {
  return (
        <div className={style.hero_wrapper}>
            <div className={style.hero_content}>
                <div className={style.hero_main}>
                    <h2 className={style.header1}><span className={style.npm}> &gt;__</span> Hello world <span className={style.emoji}> 👋</span></h2>
                    <h3><span>I&#39;m <span className={style.highlight}>Yevheniy Alekseyev</span>,</span></h3>
                    <h3><span ><span className={style.highlight}>Full-stack</span> web developer</span></h3>
                    <h4>but call me &#34;Jeka&#34; <span className={style.blinks}>|</span></h4>
                </div>
                <div className={style.photo_hero}>
                    <img src={photoHero} alt="Yevheniy Alekseyev Fullstack Developer" />
                </div>
            </div>
            <div className={style.hero_links}>
                <ul>
                    <li>
                        <Link to="./about"><Arrow />about me</Link>
                    </li>
                    <li>
                        <span>| |</span></li>
                    <li>
                        <Link to="./works">some of my works<Arrow /></Link>
                    </li>
                </ul>
            </div>
        </div>
  )
}
export default Home
