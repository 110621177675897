import style from './projects.module.scss'
import { projectList } from './projectsList.js'
import Project from '../../components/Project/Project'
const Projects = () => {
  return (
        <div className={style.projects_wrapper}>
            {projectList.map(
              (project, i) => {
                return (
                        <Project key={i} project={project}/>
                )
              }
            )}

        </div>
  )
}

export default Projects
