import style from './project.module.scss'
import PropTypes from 'prop-types'
const Project = ({ project }) => {
  return (
        <div id={`project_id${project.id}`} className={style.project}>
            <div className={style.project_header}>
                <h2>{project.title}</h2>
                <img src={`/media/${project.imgUrl.gif}`} width={600} alt="" />
            </div>
            <p className={style.desc}>{project.desc}</p>
            <div className={style.line}></div>
            <div className={style.links}>
                <a href={project.url.git} target="_blank" rel="noreferrer" > _check code</a>
                <span></span>
                <a href={project.url.live} target="_blank" rel="noreferrer" > _live</a>
            </div>
        </div>
  )
}

Project.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    imgUrl: PropTypes.shape({
      gif: PropTypes.string.isRequired
    }).isRequired,
    desc: PropTypes.string.isRequired,
    url: PropTypes.shape({
      git: PropTypes.string.isRequired,
      live: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}
export default Project
