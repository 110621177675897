// import React from 'react';
import { Link } from 'react-router-dom'
import { useState } from 'react'
import style from './header.module.scss'
import GitHub from '../Icons/GitHub'
import LinkedIn from '../Icons/LinkedIn'
import Logo from '../Icons/Logo'
import EmailIcon from '../Icons/EmailIcon'
import DownloadBtn from '../DownloadBtn/DownloadBtn'
import SocialLink from '../SocialLink/SocialLink'

const Header = () => {
  const pathname = window.location.pathname // returns the current url minus the domain name

  const [IsOpen, setIsOpen] = useState(false)
  const openMenu = () => {
    setIsOpen(!IsOpen)
  }

  return (
        <header className={style.header}>
            <button className={`${style.hamburger} ${IsOpen ? style.open : ''}`} onClick={() => openMenu()} aria-label="Open menu" ><span></span><span></span><span></span></button>
            <div className={style.logo}>
                <a href="/" target="_self" title='Yevheniy "Jeka" Alekseyev | Front-end developer'><Logo /></a>
            </div>
            <div className={`${style.social} ${IsOpen ? style.open : ''}`}>
                <SocialLink linkProps={{ href: 'mailto:greezaaa@gmail.com', children: <EmailIcon />, title: 'Send Email' }} />
                <DownloadBtn />
                <SocialLink linkProps={{ href: 'https://github.com/greezaaa', children: <GitHub />, title: 'Check GitHub profile' }} />
                <SocialLink linkProps={{ href: 'https://www.linkedin.com/in/yevheniy-jeka-alekseyev-66a69119b/', children: <LinkedIn />, title: 'Check my LinkedIn profile' }} />
            </div>
            <div className={`${style.navigation} ${IsOpen ? style.open : ''}`} >
                <Link
                    to="/"
                    className={`${style.link} ${(pathname === '/') ? style.active : ''}`}
                    onClick={() => openMenu()}
                    title='Home page'
                    >home</Link>
                <Link
                    to="/works"
                    className={`${style.link} ${(pathname === '/works') ? style.active : ''}`}
                    onClick={() => openMenu()}
                    title='Check some of my works'
                    >works</Link>
                <Link
                    to="/about"
                    className={`${style.link} ${(pathname === '/about') ? style.active : ''}`}
                    onClick={() => openMenu()}
                    title='Know more about me'
                    >about</Link>
                <Link
                    to="/contact"
                    className={`${style.link} ${(pathname === '/contact') ? style.active : ''}`}
                    onClick={() => openMenu()}
                title='Get in touch'
                    >get in touch</Link>
            </div>
        </header>
  )
}

export default Header
