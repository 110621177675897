import style from './icons.module.scss'

const LinkedIn = () => {
  return (
        <svg
            id="LinkedInLogo"
            className={`${style.icon} ${style.linkedin_icon}`}
            xmlns="http://www.w3.org/2000/svg"
            data-supported-dps="24x24"
            fill="#ffffff"
            width={24}
            height={24}
        >
            <path d="M20.5 2h-17A1.5 1.5 0 0 0 2 3.5v17A1.5 1.5 0 0 0 3.5 22h17a1.5 1.5 0 0 0 1.5-1.5v-17A1.5 1.5 0 0 0 20.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 1 1 8.3 6.5a1.78 1.78 0 0 1-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0 0 13 14.19a.66.66 0 0 0 0 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 0 1 2.7-1.4c1.55 0 3.36.86 3.36 3.66z" />
        </svg>
  )
}

export default LinkedIn
