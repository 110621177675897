import { useState } from 'react'
import style from './contactForm.module.scss'

const ContactForm = () => {
  const [status, setStatus] = useState('Submit')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStatus('Sending...')
    const { name, email, message } = e.target.elements
    const details = {
      name: name.value.trim(),
      email: email.value.trim(),
      message: message.value.trim()
    }
    e.target.reset()
    const response = await fetch('https://gray-cooperative-mite.cyclic.app/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(details)
    })
    await Promise.race([
      response.json(),
      // eslint-disable-next-line promise/param-names
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('timeout')), 5000)
      )
    ])
      .then(result => {
        console.log(result)
        setStatus('Submit')
      })
      .catch(error => {
        console.error(error)
      })
  }
  return (
    <form className={style.form} onSubmit={handleSubmit}>
      <div className={style.inputWrapper}>
        <label htmlFor="name">_name:</label>
        <input type="text" id="name" required pattern="\S+.*" />
      </div>
      <div className={style.inputWrapper}>
        <label htmlFor="email">_email:</label>
        <input type="email" id="email" required pattern="\S+.*" />
      </div>
      <div className={style.inputWrapper}>
        <label htmlFor="message">_message:</label>
        <textarea id="message" required />
      </div>
      <span> your email won&apos;t be shared with anyone</span>
      <button type="submit" disabled={false}>{status}</button>
    </form>
  )
}

export default ContactForm
