import style from './icons.module.scss'

const Arrow = () => {
  return (

      <svg

        id="ArrowIcon"
        className={`${style.icon} ${style.arrow_icon}`}
        fill="none"
        width={24}
        height={24}
        stroke="#ca2f00"
        strokeWidth="4px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 46.85 46.85"
      >

  <path d="M.35.5h46M46.35 46V0M.35 46.5l46-46" />
      </svg>

  )
}

export default Arrow
