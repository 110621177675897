import { Route, Routes } from 'react-router-dom'
import style from './main.module.scss'
import Home from '../../pages/Home/Home'
import Projects from '../../pages/Projects/Projects'
import About from '../../pages/About/About'
import Contact from '../../pages/Contact/Contact'
const Main = () => {
  return (
    <div className={style.main}>
        <Routes>
          <>
            <Route path="/" element={<Home />} />
            <Route path="/works" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </>
        </Routes>
    </div>
  )
}
export default Main
