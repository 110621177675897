import PropTypes from 'prop-types'
const SocialLink = ({ linkProps }) => {
  return (
    <a href={linkProps.href} title={linkProps.title} target="_blank" rel="noreferrer">
      {linkProps.children}
    </a>
  )
}

SocialLink.propTypes = {
  linkProps: PropTypes.shape({
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}

export default SocialLink
