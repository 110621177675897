import cvPdf from '../../assets/pdf/cv_yeveheniy-alekseyev.pdf'
import CvIcon from '../Icons/CvIcon'
import style from './downloadBtn.module.scss'
const DownloadBtn = () => {
  const onButtonClick = () => {
    fetch(cvPdf).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob)
        const alink = document.createElement('a')
        alink.href = fileURL
        alink.download = 'cv_yevheniy_alekseyev_2023.pdf'
        alink.click()
      })
    })
  }
  return (
        <>
            <button onClick={onButtonClick} className={style.downloadBtn} title="Download CV">
                <CvIcon />
            </button>
        </>
  )
}

export default DownloadBtn
