import ContactForm from '../../components/ContactForm/ContactForm'
import style from './contact.module.scss'
import GitHub from '../../components/Icons/GitHub'
import LinkedIn from '../../components/Icons/LinkedIn'
import EmailIcon from '../../components/Icons/EmailIcon'
import DownloadBtn from '../../components/DownloadBtn/DownloadBtn'
import SocialLink from '../../components/SocialLink/SocialLink'

const Contact = () => {
  return (
        <div className={style.contact_wrapper}>
            <div className={style.content}>
                <div className={style.info} >

            <h3>I love to hear from you.</h3>
                    <h4>Whether you have a question or just want to chat <span className={style.npm}>---</span> shoot me a message</h4>

                </div>
                <div className={`${style.links_wrapper}`}>
                    <SocialLink linkProps={{ href: 'mailto:greezaaa@gmail.com', children: <EmailIcon />, title: 'Send Email' }} />
                    <DownloadBtn />
                    <SocialLink linkProps={{ href: 'https://github.com/greezaaa', children: <GitHub />, title: 'Check GitHub profile' }} />
                    <SocialLink linkProps={{ href: 'https://www.linkedin.com/in/yevheniy-jeka-alekseyev-66a69119b/', children: <LinkedIn />, title: 'Check my LinkedIn profile' }} />
                </div>

            </div>
            <div>
                <ContactForm />
            </div>
        </div>
  )
}
export default Contact
